//线索总表
import request from './request.js';
import public_request from './public_request';

export function getClueAll(data) {
    return request({
        url: '/clue-pool/all/clues',
        method: 'get',
        params: public_request(data),
    });
}
export function detailClueAll({ clueId }) {
    return request({
        url: '/clue-pool/all/clue',
        method: 'get',
        params: public_request({
            clueId,
        }),
    });
}
// export function detailClueAll(data) {
//     return request({
//         url: '/clue-pool/all/clues',
//         method: 'get',
//         params: public_request(data)
//     })
// }
