<template>
    <div class="clue-all">
        <div class="page">
            <div
                style="
					text-align: center;
					color: #7f7f7f;
					font-size: 16px;
					margin: 12px 0 11px;
				"
            >线索总表</div>
            <div style="height: 100%">
                <!-- 头部搜索区域 -->
                <div class="searchOpen">
                    <search style="margin-top: 10px" :label-arr="labelArr" @clickData="clickData" />
                </div>
                <!-- 列表区域 -->
                <!-- <div style="height:calc(100vh - 100px);"> -->
                <!-- style="height:calc(100vh - 200px);margin-top:10px" -->
                <el-card :style="{ 'margin-top': '10px' }">
                    <!-- 搜索区域 -->
                    <div class="search flex-sb">
                        <div class="top-search">
                            <el-input
                                v-model="status.keyword"
                                clearable
                                size="small"
                                placeholder="请输入姓名/手机号"
                                @keypress.native.enter="findData"
                                @clear="findData"
                            />
                            <!-- <i class="el-icon-search"></i> -->
                            <div class="icon-area" @click="findData">
                                <img width="20" height="20" src="@/assets/common/search.png" alt />
                            </div>
                        </div>
                        <div class="flex-sb right-time">
                            <div>流入公共池时间</div>
                            <div>
                                <el-radio-group
                                    v-model="timeTabs"
                                    size="small"
                                    @change="changeTime"
                                >
                                    <el-radio-button label="0">全部</el-radio-button>
                                    <el-radio-button label="1">今日</el-radio-button>
                                    <el-radio-button label="2">昨日</el-radio-button>
                                    <el-radio-button label="3">最近一周</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div>
                                <el-date-picker
                                    v-model="valuetime"
                                    size="small"
                                    type="datetimerange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="timestamp"
                                    :default-time="['00:00:00', '23:59:59']"
                                    @change="time"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- 表格区域 -->
                    <!-- <div> -->
                    <el-table
                        class="table"
                        style="margin: 10px 0"
                        :data="tableData"
                        border
                        :height="tableHeight(332)"
                    >
                        <el-table-column label="序号" width="50" type="index" />
                        <el-table-column prop="name" label="客户姓名" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <div class="flex">
                                    <div style>{{ scope.row.name }}</div>
                                    <div v-if="scope.row.newClient == 1" class="new">新</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="客户资质" width="190">
                            <template slot-scope="scope">
                                <span
                                    v-if="scope.row.hasHouse == 1"
                                    class="icon house"
                                    style="margin-right: 10px"
                                >房</span>
                                <span
                                    v-if="scope.row.hasVehicle == 1"
                                    class="icon car"
                                    style="margin-right: 10px"
                                >车</span>
                                <span
                                    v-if="scope.row.hasShebao == 1"
                                    style="margin-right: 10px"
                                    class="icon shebao"
                                >社</span>
                                <span
                                    v-if="scope.row.hasGongjijin == 1"
                                    class="icon gongjijin"
                                    style="margin-right: 10px"
                                >金</span>
                                <span
                                    v-if="scope.row.hasBaodan == 1"
                                    style="margin-right: 10px"
                                    class="icon baodan"
                                >保</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="资金需求">
                            <template slot-scope="scope">
                                <span>{{ scope.row.fundNeeds }}万</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="source" label="渠道来源" />
                        <el-table-column prop="status" label="线索状态" />
                        <el-table-column prop="statusTag" label="标签" />
                        <el-table-column prop="seal" label="封存">
                            <template slot-scope="scope">{{ scope.row.sealed == 0 ? '否' : '是' }}</template>
                        </el-table-column>
                        <el-table-column prop="createdTime" label="线索创建时间" width="160" />
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="toDetail(scope.row)"
                                >查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="block" style="text-align: right">
                        <el-pagination
                            :current-page="page"
                            :page-size="size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="len"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                    <!-- </div> -->
                </el-card>
                <!-- </div> -->
                <detail-dialog ref="showDialog" :show.sync="showDialog" />
            </div>
        </div>
    </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector';
import { getClueAll } from '@/api/clue-all';
import { getYMDHMS } from '../../assets/js/time.js';
import resize from '@/mixins/resize';
export default {
    mixins: [resize],
    data() {
        return {
            valuetime: '', //时间段搜索
            timeTabs: 0, //时间段
            //表格数据字段
            tableData: [],
            status: {
                keyword: '',
                qualityType: '', //资质
                fundNeedsScopeType: '', //资金需求
                newClient: '', //新老客户
                seal: '', //封存
                startTime: '',
                endTime: '',
                pageNo: 1,
                pageSize: 10,
            },
            labelArr: ['客户资质', '资金需求', '封存', '新老客'],
            page: 1,
            len: 0,
            size: 10,
            onRiseHeight: window.innerHeight - 330,
            // tableHeight: window.innerHeight - 330,
            showDialog: false,
            //表格数据字段
            // pageHeight:'calc(100vh - 200px)'
            searchOpenHeight: '',
        };
    },
    components: {
        search: () => import('../../components/templates/search-open'),
        detailDialog: () => import('./culeAllComponents/detail-dialog'),
    },
    created() {
        // window.addEventListener("resize", this.getHeight);
        window.addEventListener('resize', this.watchSize);
        this.getTableData();
    },
    beforeMount() {},
    mounted() {
        this.watchSize();
        // this.xr(this.status);
    },
    beforeDestroy() {
        // window.removeEventListener("resize", this.getHeight);
        window.removeEventListener('resize', this.watchSize);
    },
    methods: {
        //渲染页面
        getTableData() {
            getClueAll(this.status).then((res) => {
                this.tableData = res.data.list;
                let clueStatus = this.common.allValueNeed('clue-status');
                let unconfirmedTag = this.common.allValueNeed(
                    'clue-unconfirmed-tag'
                );
                let invalidTag = this.common.allValueNeed('clue-invalid-tag');
                console.log(unconfirmedTag);
                this.tableData.forEach((item) => {
                    if (item.status == 1) {
                        unconfirmedTag.forEach((i) => {
                            if (item.statusTag == i.number) {
                                item.statusTag = i.cnVal;
                            }
                        });
                    }
                    if (item.status == 3) {
                        console.log(item);
                        invalidTag.forEach((i) => {
                            if (item.statusTag == i.number) {
                                item.statusTag = i.cnVal;
                            }
                        });
                    }
                    clueStatus.forEach((i) => {
                        if (item.status == i.number) {
                            item.status = i.cnVal;
                        }
                    });
                });
                console.log(res);
                for (let i = 0; i < this.tableData.length; i++) {
                    this.tableData[i].createdTime = getYMDHMS(
                        this.tableData[i].createdTime
                    );
                }
                this.len = res.data.total;
                // this.page =
            });
        },
        getHeight() {
            this.onRiseHeight = window.innerHeight - 330;
        },
        // 页面高度或者宽度发生变化
        watchSize() {
            const _this = this;
            const erd = elementResizeDetectorMaker({
                strategy: 'scroll',
            });
            erd.listenTo(
                document.getElementsByClassName('searchOpen'),
                (element) => {
                    let height = element.offsetHeight;
                    _this.$nextTick(() => {
                        _this.searchOpenHeight = height - 50;
                    });
                }
            );
        },
        // input事件
        findData() {
            this.status.pageNo = 1;
            // console.log(this.status.pageNo,'this.status.pageNo')
            this.page = 1;
            this.getTableData();
        },
        // 选择时间筛选
        changeTime() {
            // 起始时间
            const start = new Date(new Date(new Date().toLocaleDateString()));
            // 结束时间
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            console.log('this.valuetime', this.valuetime);
            this.status.startTime = this.valuetime[0] / 1000;
            this.status.endTime = this.valuetime[1] / 1000;
            this.status.pageSize = 10;
            this.status.pageNo = 1;
            this.page = 1;
            this.getTableData();
        },
        time(val) {
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';
                this.status.startTime = '';
                this.status.endTime = '';
            } else {
                this.timeTabs = '';
                this.valuetime = val;
                this.status.startTime = this.valuetime[0] / 1000;
                this.status.endTime = this.valuetime[1] / 1000;
            }

            this.handleCurrentChange(1);
        },
        //分页
        handleSizeChange(val) {
            this.size = val;
            this.status.pageSize = this.size;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.status.pageNo = this.page;
            this.getTableData();
        },
        clickData(val) {
            console.log(val);
            this.status.qualityType = val.qualityType;
            this.status.fundNeedsScopeType = val.fundNeedsScopeType;
            this.status.newClient =
                val.newClient == 1 ? 1 : val.newClient == 2 ? 0 : '';
            this.status.seal = val.sealUpVal;
            this.getTableData();
        },
        toDetail(info) {
            console.log(info);
            this.showDialog = true;
            this.$refs.showDialog.init(info.id, 1);
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/mini-table.less';

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clue-all {
    // height: 100%;
}

.page {
    // min-width: 1349px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.search {
    .top-search {
        position: relative;

        /deep/ .el-input__clear {
            margin-right: 23px;
        }

        .icon-area {
            position: absolute;
            right: 5px;
            top: 8px;
            border-left: 1px solid #ccc;
            padding-left: 5px;
            cursor: pointer;
        }
    }

    .right-time {
        div:not(:last-child) {
            margin-right: 10px;
        }

        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #4086ec;
            background-color: #ecf3fd;
            box-shadow: 0 0px 0px #ccc;
            border-color: #dcdfe6;
        }

        /deep/ .el-radio-button__inner {
            color: #aaa;
        }
    }
}

.new {
    background-color: #ff8040;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 2%;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}
</style>
